import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import history from '../../services/history';
import useConfigContext from '../../hooks/useConfigContext';

export default function LeftLateralBar({ by, delivery, bg }) {
  const { configState } = useConfigContext();
  const { deliveries } = configState;
  const location = useLocation();
  const splittedLocation = location.pathname.split('/');
  const selectedDelivery = splittedLocation[1];

  return (
    <Grid
      container
      justifyContent="space-around"
      style={{
        width: '100%',
        height: '70px',
      }}
    >
      {deliveries.map((deliveryObject, index) =>
        !(selectedDelivery === 'shoes' && index === 2) ? (
          <Grid
            onClick={() => {
              history.replace(`/${selectedDelivery}/${index + 1}/view`);
            }}
            style={{
              height: 30,
              cursor: 'pointer',
            }}
          >
            <Typography
              align="center"
              style={{
                textAlign: 'center',
                fontSize: 10,
                color: by === 'view' ? (bg ? '#ffffff' : '#000000') : '#000000',
                borderBottom:
                delivery === (index + 1).toString() //eslint-disable-line
                    ? bg && by === 'view'
                      ? '2px solid #ffffff'
                      : '2px solid #000000'
                    : '2px solid transparent',
                padding: '4px 15px',
                fontFamily:
                  delivery === (index + 1).toString() &&
                  'GalanoGrotesque-Medium',
                fontWeight:
                  delivery === (index + 1).toString() ? 'semibold' : '',
                // width: 105,
              }}
            >
              {deliveryObject.title}
            </Typography>
            <Typography
              align="center"
              style={{
                position: 'relative',
                top: 3,
                left: 5,
                marginLeft: -5,
                textAlign: 'center',
                fontSize: 8,
                color: by === 'view' ? (bg ? '#ffffff' : '#000000') : '#000000',
                // width: 105,
              }}
            >
              Ship {deliveryObject?.subTitle2.split('Shipping ')[1]}
            </Typography>
          </Grid>
        ) : null
      )}
    </Grid>
  );
}
