import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import { ORDERCONFIG } from '../../config/showroomConfig';
import { events } from '../../consts';
import { useEventDispatcher } from '../../hooks';

const accessCategoryObject = {
  delivery: 'categoriesOptions',
  swimwear: 'categoriesOptionsSwim',
  shoes: 'categoriesOptionsShoes',
  men: 'categoriesOptionsMen',
};

function LeftBarCategory({
  selectedCategory,
  setSelectedCategory,
  setGridSize,
  gridSize = 'Large',
  delivery,
}) {
  const dispatchEvent = useEventDispatcher();
  const [deliveryName, setDeliveryName] = useState('');

  const gridOptions = ['Small', 'Medium', 'Large'];

  const stateMachineFoward = {
    delivery: {
      1: 2,
      2: 3,
      3: 4,
      4: 5,
      5: 6,
      6: 1,
    },
    swimwear: {
      1: delivery === '3' || delivery === '2' || delivery === '1' ? 2 : 1, // BIQUINI BOTTOMS (Sempre disponível)
      2: delivery === '3' || delivery === '2' || delivery === '1' ? 3 : 1, // BIQUINI TOPS (Sempre disponível)
      3: delivery === '2' ? 4 : delivery === '3' || delivery === '1' ? 5 : 1, // COVER UP (só na entrega 2, senão SARONGS)
      4: delivery === '2' ? 5 : 6, // SARONGS (Disponível na entrega 2 e 3)
      5: delivery === '3' || delivery === '2' || delivery === '1' ? 6 : 1, // TOPS (Sempre disponível)
      6: delivery === '3' || delivery === '2' || delivery === '1' ? 7 : 1, // BOTTOMS (Sempre disponível)
      7: delivery === '3' || delivery === '2' || delivery === '1' ? 8 : 1, // DRESSES (Sempre disponível)
      8: delivery === '3' ? 1 : delivery === '1' || delivery === '2' ? 9 : 1, // JUMPSUITS (Na entrega 3 volta para 1, senão vai para 9)
      9: 1, // Reinicia para BIQUINI BOTTOMS
    },
    shoes: {
      1: 2, // FLAT SANDALS
      2: 3, // FLATS
      3: delivery === '1' ? 5 : 4, // HEELED SANDALS vai para BAGS na entrega 1, e para CLOGS AND MULES na entrega 2
      4: 1, // CLOGS AND MULES volta ao início na entrega 2
      5: 1, // BAGS volta ao início na entrega 1
    },
    men: {
      1: 2,
      2: 1,
    },
  };

  const stateMachineBackward = {
    delivery: {
      1: 6,
      2: 1,
      3: 2,
      4: 3,
      5: 4,
      6: 5,
    },
    swimwear: {
      1: delivery === '3' ? 8 : 9, // BIQUINI BOTTOMS volta para JUMPSUITS na entrega 3, senão para DRESSES
      2: 1, // BIQUINI TOPS volta para BIQUINI BOTTOMS
      3: 2, // COVER UP volta para BIQUINI TOPS
      4: delivery === '2' ? 3 : 2, // SARONGS volta para COVER UP na entrega 2, senão BIQUINI TOPS
      5: 4, // TOPS volta para SARONGS
      6: 5, // BOTTOMS volta para TOPS
      7: 6, // DRESSES volta para BOTTOMS
      8: 7, // JUMPSUITS volta para DRESSES
      9: 8, // Volta para JUMPSUITS
    },
    shoes: {
      1: delivery === '1' ? 5 : 4, // FLAT SANDALS volta para BAGS na entrega 1, e para CLOGS AND MULES na entrega 2
      2: 1, // FLATS volta para FLAT SANDALS
      3: 2, // HEELED SANDALS volta para FLATS
      4: 3, // CLOGS AND MULES volta para HEELED SANDALS na entrega 2
      5: 3, // BAGS volta para HEELED SANDALS na entrega 1
    },
    men: {
      1: 2,
      2: 1,
    },
  };

  const handleNext = () => {
    return setSelectedCategory(
      stateMachineFoward[deliveryName][selectedCategory]
    );
  };

  const handlePrev = () => {
    return setSelectedCategory(
      stateMachineBackward[deliveryName][selectedCategory]
    );
  };

  useEffect(() => {
    const splitedUrl = document.location.pathname.split('/');
    setDeliveryName(splitedUrl[1]);
  }, [setDeliveryName]);

  return (
    <Grid
      style={{
        width: 300,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          left: 113,
          top: 80,
          position: 'fixed',
          width: 180,
          paddingRight: 25,

          // backgroundColor: 'red',
        }}
      >
        <Grid>
          <Typography
            variant="h6"
            style={{
              cursor: 'pointer',
              fontSize: 14,
              color: '#000000',
              lineHeight: '20px',
              margin: 0,
              padding: 0,
              width: 175,
              // background: 'red',
              // paddingBottom: 20,
            }}
            onClick={() => setSelectedCategory(0)}
          >
            {`< `} Back to categories
          </Typography>
        </Grid>

        <Grid
          style={{
            margin: '130px 0px 60px -15px',
            padding: 0,
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize:
                ORDERCONFIG.CATEGORY.categoriesOptionsSwim[selectedCategory]
                  ?.name === 'ACCESSORIES'
                  ? 32
                  : 40,
              textAlign: 'center',
              color: '#000000',
              fontFamily: 'Galano Grotesque Light',
              lineHeight: '40px',
              transform: 'translate(-40px) rotate(-90deg) ',
              height: 80,
              width: 230,
              padding: 0,
              margin: 0,

              // backgroundColor: 'blue',
            }}
          >
            {deliveryName &&
              ORDERCONFIG.CATEGORY[accessCategoryObject[deliveryName]][
                selectedCategory
              ]?.name}
          </Typography>
        </Grid>

        <Grid container alignItems="stretch">
          <Grid container>
            <Typography
              variant="h6"
              style={{
                paddingTop: 20,
                width: 175,
                marginLeft: -10,

                textAlign: 'left',
                cursor: 'pointer',
                fontSize: 14,
                color: '#000000',
                // width: '100%',
                lineHeight: '20px',
                // paddingBottom: 20,
              }}
              onClick={handleNext}
            >
              {`> `} Next
            </Typography>
            <Typography
              variant="h6"
              style={{
                width: 175,
                marginLeft: -10,

                cursor: 'pointer',
                fontSize: 14,
                color: '#000000',
                textAlign: 'left',
                // width: '100%',
                lineHeight: '20px',
                paddingBottom: 30,
              }}
              onClick={handlePrev}
            >
              {`< `} Prev
            </Typography>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: -10 }}
          >
            {/* <Grid container justifyContent="center" alignItems="center">
              <Typography
                variant="h6"
                style={{
                  color: '#000',
                  fontSize: 14,
                  lineHeight: '20px',
                }}
              >
                Grid size
              </Typography>
              <BsGrid3X2GapFill
                style={{ fontSize: 28, color: 'black', marginLeft: 12 }}
              />
            </Grid> */}

            {gridOptions.map(option => (
              <Grid
                // alignItems="stretch"
                key={option}
                onClick={() => {
                  dispatchEvent(events.gridSize, {
                    location: 'Categories',
                  });
                  setGridSize(option);
                }}
                style={{
                  width: 100,
                  padding: '1px 22px',
                  border:
                    option === gridSize
                      ? '1px solid #2a2a2a'
                      : '1px solid transparent',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: option === gridSize ? 'bold' : '',
                    textAlign: 'center',
                    lineHeight: '20px',
                    fontSize: 10,
                    color: '#000000',
                  }}
                >
                  {option}
                </Typography>
              </Grid>
            ))}

            <Grid
              // container
              // justifyContent="center"
              // alignItems="center"
              style={{
                transform: 'rotate(-90deg)',
                position: 'absolute',
                top: 398,
                left: -15,
                // backgroundColor: 'red',
              }}
            >
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-start"
                // style={{ height: 30 }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: '#000',
                    fontSize: 12,
                    width: 60,

                    // backgroundColor: 'green',
                  }}
                >
                  Grid size
                </Typography>
                <BsGrid3X2GapFill
                  style={{
                    fontSize: 12,
                    color: 'black',
                    // marginLeft: 4,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LeftBarCategory;
