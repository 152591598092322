/* eslint-disable no-underscore-dangle */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, DialogContent, Tooltip } from '@material-ui/core';
import { AiOutlineDownload } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import api from '../../services/api';
import PDF from '../PDF';
import ProductCardCollection from './ProductCard';
import ModalProduct from '../ModalProduct';
import MenuDrawer from './MenuDrawer';
import ModalCart from '../ModalCart';
import { events, dictIdDelivery, dictIdDeliveryFormatted } from '../../consts';
import { useEventDispatcher } from '../../hooks';

import useViewAsContext from '../../hooks/useViewAsContext';

import FarmLogo from '../../assets/logo_farmRio.svg';
import useUserContext from '../../hooks/useUserContext';
import useConfigContext from '../../hooks/useConfigContext';

const StyledDialog = withStyles({
  paper: {
    margin: 0,
  },
  paperFullScreen: {
    margin: 0,
    height: '100%',
    backgroundColor: 'white',
  },
  paperWidthLg: {
    maxWidth: '100%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paperFullWidth: {
    width: '100%',
  },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalCollection({ open, handleClose }) {
  const [date] = useState(0);
  const { configState } = useConfigContext();
  const { deliveries } = configState;
  const seasonName = configState.season.name || '';
  const [myProducts, setMyProducts] = useState([]);
  const { userState } = useUserContext();
  const { user: profile } = userState;
  const [print, setPrint] = useState(false);
  const [downloadExcelURL, setExcelURL] = useState('');
  const [selectedDelivery, setSelectedDelivery] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(1);
  const [openModalProduct, setOpenModalProduct] = useState(false);
  const [d1, setD1] = useState([]);
  const [d2, setD2] = useState([]);
  const [d3, setD3] = useState([]);
  const [d4, setD4] = useState([]);
  // const [c1, setC1] = useState([]);
  const [c2, setC2] = useState([]);
  // const [c3, setC3] = useState([]);
  const [shoes1, setShoes1] = useState([]);
  const [shoes2, setShoes2] = useState([]);
  const [shoes3, setShoes3] = useState([]);
  const [swim2, setSwim2] = useState([]);
  const [swim3, setSwim3] = useState([]);
  const [men2, setMen2] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const [openCart, setOpenCart] = useState(false);
  const [styleCollection, setStyleCollection] = useState('MAIN');

  const { viewAsState } = useViewAsContext();
  const { selectedUser } = viewAsState;

  const dispatchEvent = useEventDispatcher();

  const handleModalProductClose = () => {
    setOpenModalProduct(false);
  };
  const handlePrevProduct = useCallback(() => {
    const selectedProductIndex = myProducts.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = myProducts.find(
        (p, index) => index === myProducts.length - 1
      );
      setSelectedProduct(product);
    } else {
      // volto um item
      const product = myProducts.find(
        (p, index) => index === selectedProductIndex - 1
      );

      setSelectedProduct(product);
    }
  }, [selectedProduct, myProducts]);

  const handleNextProduct = useCallback(() => {
    const selectedProductIndex = myProducts.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex >= myProducts.length - 1) {
      setSelectedProduct(myProducts[0]);
    } else {
      const product = myProducts.find(
        (p, index) => index === selectedProductIndex + 1
      );
      setSelectedProduct(product);
    }
  }, [selectedProduct, myProducts]);
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const componentRef = useRef();
  const loadExcel = useCallback(async () => {
    try {
      // const excel = await api.get('excel');
      // setExcelURL(excel.data.url);
      // setLoading(false);
    } catch (err) {
      // setLoading(false);
    }
  }, []);
  useEffect(() => {
    async function Wrap() {
      if (!open) return;
      if (!profile) return;
      if (!downloadExcelURL) {
        await loadExcel();
      }
    }
    Wrap();
  }, [loadExcel, downloadExcelURL, profile, open]);

  const handleRemoveProductFromCollection = useCallback(
    async product => {
      try {
        await api.post('/collections', {
          style_code: product.style_code,
          user_id: selectedUser._id || null,
        });
        setMyProducts(
          myProducts.filter(p => p.style_code !== product.style_code)
        );
        setAllProducts(
          allProducts.filter(p => p.style_code !== product.style_code)
        );
        setExcelURL('');
      } catch (err) {
        console.log(err);
      }
    },
    [selectedUser, myProducts, allProducts]
  );
  useEffect(() => {
    async function loadMyProducts() {
      try {
        const response = await api.get('/collections', {
          params: {
            delivery_id: null,
            categories: null,
            user_id: selectedUser?._id || null,
          },
        });
        console.log('response', response);
        const d1Array = [];
        const d2Array = [];
        const d3Array = [];
        const d4Array = [];
        const c1Array = [];
        const c2Array = [];
        const c3Array = [];
        const shoesD1 = [];
        const shoesD2 = [];
        const shoesD3 = [];
        const swimD2 = [];
        const swimD3 = [];
        const menD2 = [];
        setAllProducts(response.data);

        response.data
          .map(product => {
            if (product.delivery_id === dictIdDelivery[1]) {
              product.style_collection === 'MAIN'
                ? d1Array.push(product)
                : product.capsula === 'FOOTWEAR'
                ? shoesD1.push(product)
                : c1Array.push(product);
            }
            if (product.delivery_id === dictIdDelivery[2]) {
              product.style_collection === 'MAIN'
                ? d2Array.push(product)
                : product.capsula === 'FOOTWEAR'
                ? shoesD2.push(product)
                : product.capsula === 'SWIMWEAR'
                ? swimD2.push(product)
                : product.capsula === 'MENSWEAR'
                ? menD2.push(product)
                : c2Array.push(product);
            }
            if (product.delivery_id === dictIdDelivery[3]) {
              product.style_collection === 'MAIN'
                ? d3Array.push(product)
                : product.capsula === 'FOOTWEAR'
                ? shoesD3.push(product)
                : product.capsula === 'SWIMWEAR'
                ? swimD3.push(product)
                : c3Array.push(product);
            }

            if (product.delivery_id === dictIdDelivery[4]) {
              d4Array.push(product);
            }

            return {
              ...product,
              formatted_delivery: dictIdDeliveryFormatted[product.delivery_id],
            };
          })
          .filter(i => !!i);

        setD1(d1Array);
        setD2(d2Array);
        setD3(d3Array);
        setD4(d4Array);

        // setC1(c1Array);
        setC2(c2Array);
        // setC3(c3Array);
        setShoes1(shoesD1);
        setShoes2(shoesD2);
        setShoes3(shoesD3);
        setSwim2(swimD2);
        setSwim3(swimD3);
        setMen2(menD2);

        const deliveriesProducts = response.data.filter(
          item => item.delivery_id === dictIdDelivery[selectedDelivery]
        );

        setMyProducts(
          deliveriesProducts.filter(
            product => product?.style_collection === styleCollection
          )
        );
      } catch (err) {
        console.log(err);
      }
    }
    loadMyProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedDelivery, open, styleCollection]);

  const productsByCollectionAndCapsula = useMemo(() => {
    const differentCapsulas = [
      'MAIN COLLECTION',
      'SWIMWEAR',
      'MENSWEAR',
      'SKI',
    ];
    myProducts.forEach(prod => {
      if (!differentCapsulas.includes(prod.capsula)) {
        differentCapsulas.push(prod.capsula);
      }
    });

    const value = {};
    differentCapsulas
      .filter(i => !!i)
      .forEach(capsula => {
        value[capsula] = myProducts.filter(p => p.capsula === capsula);
      });

    return value;
  }, [myProducts]);

  // useEffect(() => {
  //   const d1Array = [];
  //   const d2Array = [];
  //   const d3Array = [];

  //   myProducts.forEach((product, index) => {
  //     if (product.delivery_id === dictIdDelivery[1]) {
  //       d1Array.push(product);
  //     }
  //     if (product.delivery_id === dictIdDelivery[2]) {
  //       d2Array.push(product);
  //     }
  //     if (product.delivery_id === dictIdDelivery[3]) {
  //       d3Array.push(product);
  //     }
  //     return {
  //       ...product,
  //       formatted_delivery: dictIdDeliveryFormatted[product.delivery_id],
  //     };
  //   });

  //   setD1(d1Array);
  //   setD2(d2Array);
  //   setD3(d3Array);
  // }, [myProducts]);
  if (!myProducts) return null;
  return (
    <div>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen
      >
        <DialogContent
          style={{
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ModalCart open={openCart} handleClose={() => setOpenCart(false)} />
          {print && (
            <Grid container ref={componentRef}>
              <PDF
                name={profile.name}
                d1={d1}
                d2={d2}
                d3={d3}
                d4={d4}
                // c1={c1}
                c2={c2}
                // c3={c3}
                shoes1={shoes1}
                shoes2={shoes2}
                shoes3={shoes3}
                swim2={swim2}
                swim3={swim3}
                men2={men2}
              />
            </Grid>
          )}
          {!print && (
            <Grid style={{ width: '100%', height: '100%' }} id="modal">
              <Grid
                container
                justifyContent="space-between"
                alignItems="space-around"
                style={{ margin: '10px 0px 0px 20px' }}
                direction="column"
              >
                {/* HEADER */}

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 16 }}
                >
                  <MenuDrawer />
                  <img
                    style={{
                      width: 250,
                      // padding: '10px 0px 20px',

                      // cursor: 'pointer',
                    }}
                    src={FarmLogo}
                    alt="Farm Rio"
                  />
                  <Grid
                    style={{
                      // width: 400,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      onClick={handleClose}
                      style={{
                        fontSize: 12,
                        cursor: 'pointer',
                        marginRight: 50,
                      }}
                    >
                      Close X
                    </Typography>
                  </Grid>
                </Grid>

                {/* first row */}
                <Grid style={{ marginTop: -8, marginBottom: 12 }}>
                  <Typography
                    align="center"
                    style={{
                      fontSize: 27,
                      letterSpacing: '0.1em',
                      fontFamily: 'Galano Grotesque Light',
                    }}
                  >
                    {!selectedUser
                      ? profile?.name &&
                        profile.name.toUpperCase().split(' ')[0]
                      : selectedUser?.name?.toUpperCase().split(' ')[0]}
                    'S{' '}
                    <Typography
                      display="inline"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 27,
                        letterSpacing: '0.1em',
                        fontFamily: 'Galano Grotesque Light',
                      }}
                    >
                      {seasonName}
                    </Typography>{' '}
                    COLLECTION
                  </Typography>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  style={{ margin: '10px 0px 40px' }}
                >
                  <Typography
                    onClick={() => {
                      setStyleCollection('MAIN');
                      setSelectedDelivery(1);
                    }}
                    style={{
                      fontWeight: styleCollection === 'MAIN' && 'bold',
                      padding: '8px 4px',
                      borderBottom:
                        styleCollection === 'MAIN' && '2px solid #000',
                      cursor: 'pointer',
                    }}
                  >
                    Ready to wear
                  </Typography>
                  <Typography
                    onClick={() => {
                      setStyleCollection('SWIMWEAR');
                      setSelectedDelivery(1);
                    }}
                    style={{
                      marginLeft: '10%',

                      fontWeight: styleCollection === 'SWIMWEAR' && 'bold',
                      padding: '8px 4px',
                      borderBottom:
                        styleCollection === 'SWIMWEAR' && '2px solid #000',
                      cursor: 'pointer',
                    }}
                  >
                    Swimwear
                  </Typography>

                  <Typography
                    onClick={() => {
                      setStyleCollection('FOOTWEAR');
                    }}
                    style={{
                      marginLeft: '10%',

                      fontWeight: styleCollection === 'FOOTWEAR' && 'bold',
                      padding: '8px 4px',
                      borderBottom:
                        styleCollection === 'FOOTWEAR' && '2px solid #000',
                      cursor: 'pointer',
                    }}
                  >
                    Shoes
                  </Typography>
                </Grid>

                {/* SECOND ROW */}
                <Grid>
                  <Grid
                    style={{ marginTop: 10 }}
                    container
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid container style={{ width: 200 }}>
                      <Typography variant="body2">
                        Download Collection
                      </Typography>
                      <Tooltip
                        title="Use 'Save as PDF' mode"
                        style={{ margin: '0 5px' }}
                      >
                        <Grid>
                          <ReactToPrint
                            trigger={() => (
                              <Grid
                                container
                                style={{
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginTop: 3,
                                  width: 41,
                                  height: 19,
                                }}
                                onClick={() => setPrint(true)}
                              >
                                <AiOutlineDownload size={16} color="black" />
                                <span style={{ fontSize: 12 }}>PDF</span>
                              </Grid>
                            )}
                            content={() => componentRef.current}
                            onAfterPrint={() => setPrint(false)}
                            onBeforeGetContent={async () => {
                              setPrint(true);
                              await timeout(1000);
                            }}
                          />
                        </Grid>
                      </Tooltip>
                      {/* start excel */}
                      {/* <ExcelDownload
                        open={open}
                        downloadExcelURL={downloadExcelURL}
                        loadingExcel={loadingExcel}
                      /> */}
                    </Grid>
                    {/* end excel */}
                    <Grid
                      container
                      style={{ width: '60%' }}
                      justifyContent={
                        styleCollection === 'SWIMWEAR'
                          ? 'space-evenly'
                          : 'space-around'
                      }
                    >
                      <Grid>
                        <Typography
                          onClick={() => setSelectedDelivery(1)}
                          style={{
                            fontWeight: selectedDelivery === 1 && 600,
                            cursor: 'pointer',
                            paddingBottom: 2,
                            borderBottom:
                              selectedDelivery === 1
                                ? '2px solid #000'
                                : '2px solid #FFF',
                          }}
                        >
                          Delivery 1
                        </Typography>
                      </Grid>

                      <Grid>
                        <Typography
                          onClick={() => setSelectedDelivery(2)}
                          style={{
                            cursor: 'pointer',
                            fontWeight: selectedDelivery === 2 && 600,
                            paddingBottom: 2,
                            borderBottom:
                              selectedDelivery === 2
                                ? '2px solid #000'
                                : '2px solid #FFF',
                          }}
                        >
                          Delivery 2
                        </Typography>
                      </Grid>
                      {styleCollection !== 'FOOTWEAR' && (
                        <Grid>
                          <Typography
                            onClick={() => setSelectedDelivery(3)}
                            style={{
                              cursor: 'pointer',
                              fontWeight: selectedDelivery === 3 && 600,
                              paddingBottom: 2,
                              borderBottom:
                                selectedDelivery === 3
                                  ? '2px solid #000'
                                  : '2px solid #FFF',
                            }}
                          >
                            Delivery 3
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      style={{
                        cursor: 'pointer',
                        backgroundColor:
                          allProducts.length > 0 ? 'black' : '#808080',
                        padding: '8px 40px',
                        marginRight: '1%',
                      }}
                      onClick={() => {
                        dispatchEvent(events.startNotes);
                        setOpenCart(true);
                      }}
                    >
                      <Typography style={{ color: '#FFFFFF' }}>
                        Start Notes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {Object.keys(productsByCollectionAndCapsula).map(collection => {
                console.log('colleection', collection);
                if (!productsByCollectionAndCapsula[collection].length)
                  return null;
                return (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      style={{
                        marginTop: 10,
                        borderBottom: '2px solid black',
                        paddingBottom: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: 600 }}>{collection} </span>{' '}
                        {deliveries[+selectedDelivery - 1].subTitle1} |{' '}
                        {deliveries[+selectedDelivery - 1].subTitle2}{' '}
                      </div>
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {productsByCollectionAndCapsula[collection].length}{' '}
                        Styles added{' '}
                      </span>{' '}
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      key={collection}
                      style={{
                        // flexGrow: 1,
                        overflowY: 'scroll',
                        // maxHeight: window.innerHeight * 0.67,
                        marginTop: 10,
                        // border: 'solid 1px black',
                        // margin: '10px 0px',
                      }}
                    >
                      {styleCollection === 'MAIN'
                        ? productsByCollectionAndCapsula[collection].map(
                            product => (
                              <ProductCardCollection
                                onClick={() => {
                                  setSelectedProduct(product);
                                  setOpenModalProduct(true);
                                }}
                                key={product.style_code}
                                handleRemoveProductFromCollection={
                                  handleRemoveProductFromCollection
                                }
                                print={print}
                                product={product}
                              />
                            )
                          )
                        : myProducts.map(product => (
                            <ProductCardCollection
                              onClick={() => {
                                setSelectedProduct(product);
                                setOpenModalProduct(true);
                              }}
                              key={product.style_code}
                              handleRemoveProductFromCollection={
                                handleRemoveProductFromCollection
                              }
                              print={print}
                              product={product}
                            />
                          ))}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          )}
          <ModalProduct
            products={myProducts}
            // setProducts={setProducts}
            handlePrevProduct={handlePrevProduct}
            handleNextProduct={handleNextProduct}
            open={openModalProduct}
            handleClose={handleModalProductClose}
            style_code={selectedProduct}
          />
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
