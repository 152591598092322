import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { FiShoppingBag, FiHeart, FiUser } from 'react-icons/fi';

import Divider from '../atoms/Divider';

import FarmLogo from '../../assets/logo_farmRio.svg';

import useUserContext from '../../hooks/useUserContext';
import useModalLoginContext from '../../hooks/useModalLoginContext';

import globalStyles from '../../styles/global.module.scss';

import styles from './styles.module.scss';

export default function PersistentDrawerLeft() {
  const { userState, logOut } = useUserContext();

  const signed = Boolean(userState.token);

  // const isAdminOrSelectedEmails = useMemo(
  //   () =>
  //     userState.user?.admin ||
  //     userState.user?.email?.includes('nordstrom') ||
  //     userState.user?.email?.includes('anthropologie') ||
  //     userState.user?.email?.includes('saks'),
  //   [userState.user.admin, userState.user.email]
  // );

  // const displayShoes = isAdminOrSelectedEmails;

  const { show: openModalLogin } = useModalLoginContext();

  const userNameFormatted = useMemo(() => {
    if (!userState.user.name) return '';
    return userState.user.name.toUpperCase();
  }, [userState.user]);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const shopNowLinks = [
    {
      title: 'Ready to Wear',
      active: true,
      links: [
        {
          href: '/delivery/1/view',
          text: 'Delivery 1',
        },
        {
          href: '/delivery/2/view',
          text: 'Delivery 2',
        },
        {
          href: '/delivery/3/view',
          text: 'Delivery 3',
        },
      ],
    },
    {
      title: 'Swimwear',
      active: true,
      links: [
        {
          href: '/swimwear/1/view',
          text: 'Delivery 1',
        },
        {
          href: '/swimwear/2/view',
          text: 'Delivery 2',
        },
        {
          href: '/swimwear/3/view',
          text: 'Delivery 3',
        },
      ],
    },
    {
      title: 'Shoes',
      active: true,
      links: [
        {
          href: '/shoes/1/view',
          text: 'Delivery 1',
        },
        {
          href: '/shoes/2/view',
          text: 'Delivery 2',
        },
        // {
        //   href: '/shoes/3/view',
        //   text: 'Delivery 3',
        // },
      ],
    },
    {
      title: 'Menswear',
      active: false,
      links: [
        {
          href: '/men/2/view',
          text: 'Delivery 2',
        },
      ],
    },
  ];

  const clientAreaLinks = [
    {
      text: 'Your Collection',
      href: '/collection',
      Icon: FiHeart,
    },
    {
      text: 'Your Order',
      href: '/orders',
      Icon: FiShoppingBag,
    },
    {
      text: 'Client Area',
      href: '/client',
      Icon: FiUser,
    },
  ];

  const whoWeAreLinks = [
    {
      text: 'Home',
      href: '/',
    },
    {
      text: 'Who we are',
      href: '/whoweare',
    },
    {
      text: 'Sustainability',
      href: '/sustainability',
    },
  ];

  return (
    <>
      <CssBaseline />

      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
      >
        <MenuIcon style={{ color: '#000' }} />
      </IconButton>
      <Drawer
        onClose={handleDrawerClose}
        variant="temporary"
        anchor="left"
        open={open}
      >
        <div className={styles.drawerWrapper}>
          <header className={styles.header}>
            <IconButton
              className={styles.closeMenuButton}
              onClick={handleDrawerClose}
              size="small"
            >
              <CloseIcon color="primary" />
            </IconButton>
            <img className={styles.logo} src={FarmLogo} alt="Logo Farm" />
          </header>

          <div className={styles.topSection}>
            {signed && (
              <>
                <div>Hello {userNameFormatted}</div>
                <div className={styles.logOutSection}>
                  Not {userNameFormatted}?{' '}
                  <button
                    className={`${globalStyles.textButton} ${styles.logoutButton}`}
                    type="button"
                    onClick={logOut}
                  >
                    Click here
                  </button>
                </div>
              </>
            )}

            {!signed && (
              <button
                className={`${globalStyles.textButton} ${styles.logoutButton}`}
                type="button"
                onClick={() => {
                  handleDrawerClose();
                  openModalLogin();
                }}
              >
                Login
              </button>
            )}
          </div>

          <div className={styles.linksSection}>
            <h2 className={styles.title}>Shop Now</h2>
            <Divider />

            {shopNowLinks.map(({ title, links, active }) => {
              if (!active) return null;
              return (
                <div key={title} className={styles.linksWrapper}>
                  <h3 className={styles.subtitle}>{title}</h3>
                  <ul className={`${styles.links} ${styles.horizontal}`}>
                    {links.map(({ href, text }) => (
                      <li key={href}>
                        <Link className={globalStyles.link} to={href}>
                          {text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>

          {signed && (
            <div className={styles.linksSection}>
              <h2 className={styles.title}>Client Area</h2>
              <Divider />
              {clientAreaLinks.map(({ text, href, Icon }) => {
                return (
                  <h3 className={styles.bigLink} key={href}>
                    <Link
                      className={`${globalStyles.link} ${styles.link}`}
                      to={href}
                    >
                      {text}
                      <Icon />
                    </Link>
                  </h3>
                );
              })}
            </div>
          )}

          <div className={styles.linksSection}>
            <h2 className={styles.title}>Who we are</h2>
            <Divider />
            {whoWeAreLinks.map(({ text, href }) => {
              return (
                <h3 className={styles.bigLink} key={href}>
                  <Link
                    className={`${globalStyles.link} ${styles.link}`}
                    to={href}
                  >
                    {text}
                  </Link>
                </h3>
              );
            })}
          </div>
        </div>
      </Drawer>
    </>
  );
}
