export const CURRENT_COLLECTION = 'SS25';

export const collectionViewsOptions = ['View', 'Category'];

export const categoryViewOptions = {
  delivery: {
    isActive: true,
    categoriesArray: [
      {
        categoryName: 'DRESSES',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'TOPS AND BLOUSES',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'BOTTOMS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'JUMPSUITS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'KNITWEAR',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'OUTERWEAR',
        activeDeliveries: [1, 2, 3],
      },
    ],
  },
  swimwear: {
    isActive: true,
    categoriesArray: [
      {
        categoryName: 'BIKINI BOTTOMS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'BIKINI TOPS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'COVER UP',
        activeDeliveries: [1, 2, 3],
      },
      // {
      //   categoryName: 'ONE PIECE',
      //   activeDeliveries: [1, 2, 3],
      // },
      {
        categoryName: 'SARONGS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'TOPS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'BOTTOMS',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'DRESSES',
        activeDeliveries: [1, 2, 3],
      },
      {
        categoryName: 'JUMPSUITS',
        activeDeliveries: [3],
      },
    ],
  },
  shoes: {
    isActive: false,
    categoriesArray: [
      {
        categoryName: 'FLAT SANDALS',
        activeDeliveries: [1, 2],
      },
      {
        categoryName: 'FLATS',
        activeDeliveries: [1, 2],
      },
      {
        categoryName: 'HEELED SANDALS',
        activeDeliveries: [1, 2],
      },
      {
        categoryName: 'CLOGS AND MULES',
        activeDeliveries: [1],
      },
      {
        categoryName: 'BAGS',
        activeDeliveries: [2],
      },
      // {
      //   categoryName: 'SNEAKERS',
      //   activeDeliveries: [1, 2, 3],
      // },
    ],
  },
  men: {
    isActive: false,
    categoriesArray: [
      {
        categoryName: 'TOPS',
        activeDeliveries: [2],
      },
      {
        categoryName: 'BOTTOMS',
        activeDeliveries: [2],
      },
    ],
  },
};

export const ORDERCONFIG = {
  TAKEAWAY: {
    ORDENACAO: {
      '2': [
        {
          style_code: '294923',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/01_294923.jpg',
          position: '01',
        },
        {
          style_code: '294922',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/02_294922.jpg',
          position: '02',
        },
        {
          style_code: '294927',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/03_294927.jpg',
          position: '03',
        },
        {
          style_code: '295018',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/04_295018.jpg',
          position: '04',
        },
        {
          style_code: '298005',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/05_298005.jpg',
          position: '05',
        },
        // {
        //   style_code: '301631',
        //   overview_image:
        //     'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/06_301631.jpg',
        //   position: '06',
        // },
        {
          style_code: '301635',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/07_301635.jpg',
          position: '07',
        },
        {
          style_code: '301636',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/08_301636.jpg',
          position: '08',
        },
        {
          style_code: '302288',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/09_302288.jpg',
          position: '09',
        },
        {
          style_code: '302287',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/10_302287.jpg',
          position: '10',
        },
        {
          style_code: '294928',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/11_294928.jpg',
          position: '11',
        },
        {
          style_code: '294947',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/12_294947.jpg',
          position: '12',
        },
        {
          style_code: '294946',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/13_294946.jpg',
          position: '13',
        },
        {
          style_code: '294882',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/14_294882.jpg',
          position: '14',
        },
        {
          style_code: '294942',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/15_294942.jpg',
          position: '15',
        },
        {
          style_code: '294941',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/16_294941.jpg',
          position: '16',
        },
        {
          style_code: '297889',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/17_297889.jpg',
          position: '17',
        },
        {
          style_code: '295521',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/18_295521.jpg',
          position: '18',
        },
        {
          style_code: '297939',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/19_297939.jpg',
          position: '19',
        },
        {
          style_code: '294929',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/20_294929.jpg',
          position: '20',
        },
        // {
        //   style_code: '294869',
        //   overview_image:
        //     'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/21_294869.jpg',
        //   position: '21',
        // },
        {
          style_code: '301492',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/22_301492.jpg',
          position: '22',
        },
        {
          style_code: '301493',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/23_301493.jpg',
          position: '23',
        },
        {
          style_code: '294921',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/24_294921.jpg',
          position: '24',
        },
        {
          style_code: '294888',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/25_294888.jpg',
          position: '25',
        },
        {
          style_code: '297971',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/26_297971.jpg',
          position: '26',
        },
        {
          style_code: '294857',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/27_294857.jpg',
          position: '27',
        },
        {
          style_code: '294968',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/28_294968.jpg',
          position: '28',
        },
        {
          style_code: '294867',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/29_294867.jpg',
          position: '29',
        },
        {
          style_code: '297966',
          overview_image:
            'https://storage.googleapis.com/showroom-bucket-images/Delivery2_RESORT22/4_takeaway/30_297966.jpg',
          position: '30',
        },
      ],
    },
  },
  PRINT: {
    ORDENACAO_POR_DELIVERY_PREFALL: {
      '1': [
        {
          name: 'AINIKA TAPESTRY RED',
          ordem: '01',
          style_collection: 'MAIN',
        },

        {
          name: 'BANANA SKY',
          ordem: '02',
          style_collection: 'MAIN',
        },
        {
          name: 'BLACK',
          ordem: '03',
          style_collection: 'MAIN',
        },
        {
          name: 'AMAZON MUSHROOM',
          ordem: '04',
          style_collection: 'MAIN',
        },
        {
          name: 'BLUE',
          ordem: '05',
          style_collection: 'MAIN',
        },
        {
          name: 'BROWN',
          ordem: '06',
          style_collection: 'MAIN',
        },
        {
          name: 'BURGUNDY',
          ordem: '07',
          style_collection: 'MAIN',
        },
      ],
      '2': [
        {
          name: null,
          ordem: '01',
          style_collection: 'MAIN',
        },
        {
          name: 'BANANA SCARVES',
          ordem: '02',
          style_collection: 'MAIN',
        },
        {
          name: 'COPACABANA',
          ordem: '03',
          style_collection: 'MAIN',
        },
        {
          name: 'FRUITS SCARVES',
          ordem: '04',
          style_collection: 'MAIN',
        },
        {
          name: 'GRAPHIC SUNSHINE',
          ordem: '05',
          style_collection: 'MAIN',
        },
        {
          name: 'HAPPY TOUCANS',
          ordem: '06',
          style_collection: 'MAIN',
        },
        {
          name: 'MACAW PARTY',
          ordem: '07',
          style_collection: 'MAIN',
        },
        {
          name: 'SUMMERY SALAD',
          ordem: '08',
          style_collection: 'MAIN',
        },
        {
          name: 'PINK FRUITS OCEAN',
          ordem: '09',
          style_collection: 'MAIN',
        },
        {
          name: 'RED FRUITS OCEAN',
          ordem: '10',
          style_collection: 'MAIN',
        },
        {
          name: 'LIGHT CHEVRON',
          ordem: '11',
          style_collection: 'MAIN',
        },
      ],
    },
    ORDENACAO_POR_DELIVERY: {
      '1': [
        {
          name: 'AINIKA TAPESTRY RED',
          ordem: '1',
          style_collection: 'MAIN',
        },
        {
          name: 'BANANA SKY',
          ordem: '2',
          style_collection: 'MAIN',
        },
        {
          name: 'BLACK',
          ordem: '3',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'BLACK AND WHITE WILD HEARTS',
        //   ordem: '4',
        //   style_collection: 'MAIN',
        // },
        // {
        //   name: 'BLUE',
        //   ordem: '5',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'BROWN',
          ordem: '6',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'BURGUNDY',
        //   ordem: '7',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'CERRADO SKY BLACK AND WHITE',
          ordem: '8',
          style_collection: 'MAIN',
        },
        {
          name: 'DELICATE LEAVES',
          ordem: '9',
          style_collection: 'MAIN',
        },
        {
          name: 'DOTTED MACAWS',
          ordem: '10',
          style_collection: 'MAIN',
        },
        {
          name: 'DREAM COLLAGE LIGHT PINK',
          ordem: '11',
          style_collection: 'MAIN',
        },
        {
          name: 'FLORAL DREAM',
          ordem: '12',
          style_collection: 'MAIN',
        },
        {
          name: 'FLOWER SCARVES',
          ordem: '13',
          style_collection: 'MAIN',
        },
        {
          name: 'FLOWERED LEOPARDS',
          ordem: '14',
          style_collection: 'MAIN',
        },
        {
          name: 'FLOWERS GARDEN BLACK',
          ordem: '15',
          style_collection: 'MAIN',
        },
        {
          name: 'JULIE STRIPES',
          ordem: '16',
          style_collection: 'MAIN',
        },
        {
          name: 'LEOPARD POP BLUE',
          ordem: '17',
          style_collection: 'MAIN',
        },
        {
          name: 'LEOPARD POP CARAMEL',
          ordem: '18',
          style_collection: 'MAIN',
        },
        {
          name: 'LEOPARD TOUCANS',
          ordem: '19',
          style_collection: 'MAIN',
        },
        {
          name: 'LISA',
          ordem: '20',
          style_collection: 'MAIN',
        },
        {
          name: 'AMAZON MUSHROOM',
          ordem: '21',
          style_collection: 'MAIN',
        },
        {
          name: 'COLORFUL SMILE',
          ordem: '22',
          style_collection: 'MAIN',
        },
        {
          name: 'PINK',
          ordem: '23',
          style_collection: 'MAIN',
        },
        {
          name: 'RAINBOW SUNS',
          ordem: '24',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'RAINBOW WILD HEARTS',
        //   ordem: '25',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'SUNSET TAPESTRY BLACK',
          ordem: '26',
          style_collection: 'MAIN',
        },
        {
          name: 'TAPESTRY SCARF',
          ordem: '27',
          style_collection: 'MAIN',
        },
        {
          name: 'TAPESTRY SCARF BLUE',
          ordem: '28',
          style_collection: 'MAIN',
        },
        {
          name: 'WILD JUNGLE BROWN',
          ordem: '30',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'WILD JUNGLE CARAMEL',
        //   ordem: '31',
        //   style_collection: 'MAIN',
        // },
      ],
      2: [
        {
          name: 'AINIKA TAPESTRY BLUE',
          ordem: '1',
          style_collection: 'MAIN',
        },
        {
          name: 'AMAZON FOREST MUSHROOM',
          ordem: '2',
          style_collection: 'MAIN',
        },
        {
          name: 'AMAZONIA',
          ordem: '3',
          style_collection: 'MAIN',
        },
        {
          name: 'BANANICA BLACK',
          ordem: '4',
          style_collection: 'MAIN',
        },
        {
          name: 'BANANICA NAVY BLUE',
          ordem: '6',
          style_collection: 'MAIN',
        },
        {
          name: 'BLACK',
          ordem: '7',
          style_collection: 'MAIN',
        },
        {
          name: 'BLUE',
          ordem: '8',
          style_collection: 'MAIN',
        },
        {
          name: 'BOROGODO TOUCANS',
          ordem: '9',
          style_collection: 'MAIN',
        },
        {
          name: 'FLOWER STRIPES CARAMEL',
          ordem: '10',
          style_collection: 'MAIN',
        },
        {
          name: 'FLOWERS TAPESTRY',
          ordem: '11',
          style_collection: 'MAIN',
        },
        {
          name: 'FULL OF HEARTS',
          ordem: '12',
          style_collection: 'MAIN',
        },
        {
          name: 'GARDEN BLACK',
          ordem: '13',
          style_collection: 'MAIN',
        },
        {
          name: 'GARDEN NAVY BLUE',
          ordem: '14',
          style_collection: 'MAIN',
        },
        {
          name: 'GRAPHIC ELEMENTS AINIKA',
          ordem: '15',
          style_collection: 'MAIN',
        },
        {
          name: 'GRAPHIC TOUCANS',
          ordem: '16',
          style_collection: 'MAIN',
        },
        {
          name: 'LILYS GARDEN',
          ordem: '17',
          style_collection: 'MAIN',
        },
        {
          name: 'EMBROIDERED CORDUROY',
          ordem: '18',
          style_collection: 'MAIN',
        },
        {
          name: 'NEON MUSHROOMS',
          ordem: '19',
          style_collection: 'MAIN',
        },
        {
          name: 'PINEAPPLE FLOWERS NAVY BLUE',
          ordem: '20',
          style_collection: 'MAIN',
        },
        {
          name: 'PINEAPPLE FLOWERS YELLOW',
          ordem: '21',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'PINK SNAKE FRUIT',
        //   ordem: '22',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'RAINBOW MIX',
          ordem: '23',
          style_collection: 'MAIN',
        },
        {
          name: 'SAND YAWANAWA VANAHU',
          ordem: '24',
          style_collection: 'MAIN',
        },
        {
          name: 'SNAKE FRUIT PINK',
          ordem: '25',
          style_collection: 'MAIN',
        },
        {
          name: 'SNAKE GARDEN RED',
          ordem: '26',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'TAPESTRY SCARF',
        //   ordem: '27',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'YAWANAWA VANAHU NAVY BLUE',
          ordem: '28',
          style_collection: 'MAIN',
        },
        {
          name: 'WILD HEARTS BLACK AND WHITE',
          ordem: '29',
          style_collection: 'MAIN',
        },
      ],
      3: [
        {
          name: 'BANANA SKY BLACK',
          ordem: '1',
          style_collection: 'MAIN',
        },
        {
          name: 'BLACK',
          ordem: '2',
          style_collection: 'MAIN',
        },
        {
          name: 'BURGUNDY',
          ordem: '3',
          style_collection: 'MAIN',
        },
        {
          name: 'CERRADO SKY BLACK',
          ordem: '4',
          style_collection: 'MAIN',
        },
        {
          name: 'DOTTED FOREST RED',
          ordem: '5',
          style_collection: 'MAIN',
        },
        {
          name: 'FRUIT PARADISE BLACK',
          ordem: '6',
          style_collection: 'MAIN',
        },
        {
          name: 'GRAPHIC TOUCANS BLACK',
          ordem: '7',
          style_collection: 'MAIN',
        },
        {
          name: 'IKAT DYED HEARTS NAVY BLUE',
          ordem: '8',
          style_collection: 'MAIN',
        },
        {
          name: 'LEOPARD MIX NAVY BLUE',
          ordem: '9',
          style_collection: 'MAIN',
        },
        {
          name: 'LISA',
          ordem: '10',
          style_collection: 'MAIN',
        },
        {
          name: 'LOUISE FLORAL BLACK',
          ordem: '11',
          style_collection: 'MAIN',
        },
        {
          name: 'MIXED SCARVES BLACK',
          ordem: '12',
          style_collection: 'MAIN',
        },
        {
          name: 'MULTICOLORED',
          ordem: '13',
          style_collection: 'MAIN',
        },
        {
          name: 'MUSHROOM FOREST OFF-WHITE',
          ordem: '14',
          style_collection: 'MAIN',
        },
        {
          name: 'MYSTIC TRIP',
          ordem: '15',
          style_collection: 'MAIN',
        },
        {
          name: 'OFF-WHITE',
          ordem: '16',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'PINEAPPLE FLOWERS NAVY BLUE',
        //   ordem: '17',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'RAINBOW WILD HEARTS',
          ordem: '18',
          style_collection: 'MAIN',
        },
        {
          name: 'RAINFOREST BLACK',
          ordem: '19',
          style_collection: 'MAIN',
        },
        {
          name: 'ROMANTIC GARDEN',
          ordem: '20',
          style_collection: 'MAIN',
        },
        {
          name: 'SNAKE FRUIT BLACK',
          ordem: '21',
          style_collection: 'MAIN',
        },
        {
          name: 'SNAKE GARDEN BLACK',
          ordem: '22',
          style_collection: 'MAIN',
        },
        {
          name: 'SNAKE GARDEN RED',
          ordem: '23',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'SUNSET TAPESTRY BLACK',
        //   ordem: '24',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'SUNSET TAPESTRY BLUSH PINK',
          ordem: '25',
          style_collection: 'MAIN',
        },
        {
          name: 'TOUCANFLY BLACK',
          ordem: '26',
          style_collection: 'MAIN',
        },
        // {
        //   name: 'WHITE',
        //   ordem: '27',
        //   style_collection: 'MAIN',
        // },
        {
          name: 'WILD JUNGLE GREEN',
          ordem: '28',
          style_collection: 'MAIN',
        },
      ],
    },
  },
  CATEGORY: {
    // PRODUTOS_ORDENADOS_POR_CATEGORIA_E_DELIVERY: {
    //   '1': {
    //     BOTTOMS: [
    //       {
    //         style_code: '294861',
    //         position: '01',
    //       },
    //       {
    //         style_code: '297925',
    //         position: '02',
    //       },
    //       {
    //         style_code: '297972',
    //         position: '03',
    //       },
    //       {
    //         style_code: '301615',
    //         position: '04',
    //       },
    //       {
    //         style_code: '295008',
    //         position: '05',
    //       },
    //       {
    //         style_code: '293167',
    //         position: '06',
    //       },
    //     ],
    //     DRESSES: [
    //       {
    //         style_code: '294848',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294833',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294823',
    //         position: '03',
    //       },
    //       {
    //         style_code: '297885',
    //         position: '04',
    //       },
    //       {
    //         style_code: '294863',
    //         position: '05',
    //       },
    //       {
    //         style_code: '302290',
    //         position: '06',
    //       },
    //       {
    //         style_code: '302379',
    //         position: '07',
    //       },
    //       {
    //         style_code: '302289',
    //         position: '08',
    //       },
    //       {
    //         style_code: '294916',
    //         position: '09',
    //       },
    //       {
    //         style_code: '294991',
    //         position: '09',
    //       },
    //       {
    //         style_code: '294979',
    //         position: '10',
    //       },
    //       {
    //         style_code: '297962',
    //         position: '11',
    //       },
    //       {
    //         style_code: '297866',
    //         position: '12',
    //       },
    //       {
    //         style_code: '297927',
    //         position: '13',
    //       },
    //       {
    //         style_code: '297886',
    //         position: '14',
    //       },
    //       {
    //         style_code: '294879',
    //         position: '15',
    //       },
    //       {
    //         style_code: '295599',
    //         position: '16',
    //       },
    //       {
    //         style_code: '294974',
    //         position: '17',
    //       },
    //       {
    //         style_code: '294903',
    //         position: '18',
    //       },
    //       {
    //         style_code: '294935',
    //         position: '19',
    //       },
    //       {
    //         style_code: '301634',
    //         position: '20',
    //       },
    //     ],
    //     SKIRTS: [
    //       {
    //         style_code: '297947',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294954',
    //         position: '02',
    //       },
    //     ],
    //     KNITWEAR: [
    //       {
    //         style_code: '295019',
    //         position: '01',
    //       },
    //       {
    //         style_code: '295015',
    //         position: '02',
    //       },
    //       {
    //         style_code: '295580',
    //         position: '03',
    //       },
    //       {
    //         style_code: '294956',
    //         position: '04',
    //       },
    //       {
    //         style_code: '301299',
    //         position: '05',
    //       },
    //       {
    //         style_code: '297965',
    //         position: '06',
    //       },
    //       {
    //         style_code: '301304',
    //         position: '07',
    //       },
    //       {
    //         style_code: '299864',
    //         position: '08',
    //       },
    //       {
    //         style_code: '297482',
    //         position: '09',
    //       },
    //       {
    //         style_code: '295569',
    //         position: '10',
    //       },
    //       {
    //         style_code: '298071',
    //         position: '11',
    //       },
    //       {
    //         style_code: '295566',
    //         position: '12',
    //       },
    //       {
    //         style_code: '294936',
    //         position: '13',
    //       },
    //       {
    //         style_code: '297982',
    //         position: '14',
    //       },
    //       {
    //         style_code: '294956',
    //         position: '15',
    //       },
    //     ],
    //     OUTERWEAR: [
    //       {
    //         style_code: '298304',
    //         position: '01',
    //       },
    //       {
    //         style_code: '295003',
    //         position: '02',
    //       },
    //       {
    //         style_code: '295005',
    //         position: '03',
    //       },
    //       {
    //         style_code: '295006',
    //         position: '04',
    //       },
    //       {
    //         style_code: '295009',
    //         position: '05',
    //       },
    //       {
    //         style_code: '295004',
    //         position: '06',
    //       },
    //     ],
    //     'TOPS AND BLOUSES': [
    //       {
    //         style_code: '297876',
    //         position: '01',
    //       },
    //       {
    //         style_code: '297882',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294911',
    //         position: '03',
    //       },
    //       // {
    //       //   "style_code": null,
    //       //   "position": "04"
    //       // },
    //       {
    //         style_code: '294985',
    //         position: '05',
    //       },
    //     ],
    //   },
    //   '2': {
    //     BOTTOMS: [
    //       {
    //         style_code: '294947',
    //         position: '01',
    //       },
    //       {
    //         style_code: '301636',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294857',
    //         position: '03',
    //       },
    //       {
    //         style_code: '297881',
    //         position: '04',
    //       },
    //       {
    //         style_code: '295018',
    //         position: '05',
    //       },
    //       {
    //         style_code: '294921',
    //         position: '06',
    //       },
    //       {
    //         style_code: '294888',
    //         position: '07',
    //       },
    //       {
    //         style_code: '295571',
    //         position: '08',
    //       },
    //       {
    //         style_code: '298027',
    //         position: '09',
    //       },
    //       {
    //         style_code: '293167',
    //         position: '10',
    //       },
    //       {
    //         style_code: '294906',
    //         position: '11',
    //       },
    //       {
    //         style_code: '295606',
    //         position: '12',
    //       },
    //       {
    //         style_code: '294906',
    //         position: '13',
    //       },
    //     ],
    //     DRESSES: [
    //       {
    //         style_code: '297939',
    //         position: '01',
    //       },
    //       {
    //         style_code: '301493',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294869',
    //         position: '03',
    //       },
    //       {
    //         style_code: '301492',
    //         position: '04',
    //       },
    //       {
    //         style_code: '297971',
    //         position: '05',
    //       },
    //       {
    //         style_code: '294941',
    //         position: '06',
    //       },
    //       {
    //         style_code: '294927',
    //         position: '07',
    //       },
    //       {
    //         style_code: '301631',
    //         position: '08',
    //       },
    //       {
    //         style_code: '302287',
    //         position: '09',
    //       },
    //       {
    //         style_code: '302288',
    //         position: '10',
    //       },
    //       {
    //         style_code: '294882',
    //         position: '11',
    //       },
    //       {
    //         style_code: '294867',
    //         position: '12',
    //       },
    //       {
    //         style_code: '294968',
    //         position: '13',
    //       },
    //       {
    //         style_code: '294850',
    //         position: '14',
    //       },
    //       {
    //         style_code: '294839',
    //         position: '15',
    //       },
    //       {
    //         style_code: '294883',
    //         position: '16',
    //       },
    //       {
    //         style_code: '297887',
    //         position: '17',
    //       },
    //       {
    //         style_code: '297970',
    //         position: '18',
    //       },
    //       {
    //         style_code: '297969',
    //         position: '19',
    //       },
    //       {
    //         style_code: '297862',
    //         position: '20',
    //       },
    //       {
    //         style_code: '294847',
    //         position: '21',
    //       },
    //       {
    //         style_code: '294862',
    //         position: '22',
    //       },
    //       {
    //         style_code: '297942',
    //         position: '23',
    //       },
    //       {
    //         style_code: '294945',
    //         position: '24',
    //       },
    //       {
    //         style_code: '297884',
    //         position: '25',
    //       },
    //       {
    //         style_code: '294832',
    //         position: '26',
    //       },
    //       {
    //         style_code: '294931',
    //         position: '27',
    //       },
    //       {
    //         style_code: '295518',
    //         position: '28',
    //       },
    //       {
    //         style_code: '297984',
    //         position: '29',
    //       },
    //       {
    //         style_code: '294827',
    //         position: '30',
    //       },
    //       {
    //         style_code: '294837',
    //         position: '31',
    //       },
    //       {
    //         style_code: '294904',
    //         position: '32',
    //       },
    //     ],
    //     SKIRTS: [
    //       {
    //         style_code: '294923',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294929',
    //         position: '02',
    //       },
    //       {
    //         style_code: '295521',
    //         position: '03',
    //       },
    //       {
    //         style_code: '298006',
    //         position: '04',
    //       },
    //       {
    //         style_code: '297865',
    //         position: '05',
    //       },
    //       {
    //         style_code: '294950',
    //         position: '06',
    //       },
    //       {
    //         style_code: '297888',
    //         position: '07',
    //       },
    //       {
    //         style_code: '298026',
    //         position: '08',
    //       },
    //       // {
    //       //   style_code: null,
    //       //   position: '09',
    //       // },
    //     ],
    //     KNITWEAR: [
    //       {
    //         style_code: '294955',
    //         position: '01',
    //       },
    //       {
    //         style_code: '295013',
    //         position: '02',
    //       },
    //       {
    //         style_code: '295530',
    //         position: '03',
    //       },
    //       {
    //         style_code: '301303',
    //         position: '04',
    //       },
    //       {
    //         style_code: '295571',
    //         position: '05',
    //       },
    //       {
    //         style_code: '295572',
    //         position: '06',
    //       },
    //       {
    //         style_code: '299191',
    //         position: '07',
    //       },
    //       {
    //         style_code: '301635',
    //         position: '08',
    //       },
    //       // {
    //       //   style_code: null,
    //       //   position: '09',
    //       // },
    //       {
    //         style_code: '295563',
    //         position: '10',
    //       },
    //       {
    //         style_code: '298070',
    //         position: '11',
    //       },
    //       {
    //         style_code: '301300',
    //         position: '12',
    //       },
    //     ],
    //     'TOPS AND BLOUSES': [
    //       {
    //         style_code: '294922',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294928',
    //         position: '02',
    //       },
    //       {
    //         style_code: '298005',
    //         position: '03',
    //       },
    //       {
    //         style_code: '294946',
    //         position: '04',
    //       },
    //       {
    //         style_code: '297889',
    //         position: '05',
    //       },
    //       {
    //         style_code: '297966',
    //         position: '06',
    //       },
    //       {
    //         style_code: '294951',
    //         position: '07',
    //       },
    //       {
    //         style_code: '294905',
    //         position: '08',
    //       },
    //       {
    //         style_code: '301501',
    //         position: '09',
    //       },
    //       {
    //         style_code: '294838',
    //         position: '10',
    //       },
    //       {
    //         style_code: '295021',
    //         position: '11',
    //       },
    //       {
    //         style_code: '297944',
    //         position: '12',
    //       },
    //       {
    //         style_code: '297932',
    //         position: '13',
    //       },
    //       {
    //         style_code: '294887',
    //         position: '14',
    //       },
    //       // {
    //       //   style_code: null,
    //       //   position: '15',
    //       // },
    //     ],
    //     OUTERWEAR: [
    //       {
    //         style_code: '293168',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294883',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294942',
    //         position: '03',
    //       },
    //     ],
    //   },
    //   '3': {
    //     BOTTOMS: [
    //       {
    //         style_code: '294971',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294973',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294988',
    //         position: '03',
    //       },
    //     ],
    //     DRESSES: [
    //       {
    //         style_code: '294994',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294855',
    //         position: '02',
    //       },
    //       {
    //         style_code: '294990',
    //         position: '03',
    //       },
    //       {
    //         style_code: '294992',
    //         position: '04',
    //       },
    //       {
    //         style_code: '294981',
    //         position: '05',
    //       },
    //       {
    //         style_code: '301633',
    //         position: '06',
    //       },
    //       {
    //         style_code: '294996',
    //         position: '07',
    //       },
    //       {
    //         style_code: '297875',
    //         position: '08',
    //       },
    //       {
    //         style_code: '297871',
    //         position: '09',
    //       },
    //       {
    //         style_code: '297872',
    //         position: '10',
    //       },
    //       {
    //         style_code: '297880',
    //         position: '11',
    //       },
    //       {
    //         style_code: '294829',
    //         position: '12',
    //       },
    //       {
    //         style_code: '297968',
    //         position: '13',
    //       },
    //       {
    //         style_code: '294995',
    //         position: '14',
    //       },
    //       {
    //         style_code: '294986',
    //         position: '15',
    //       },
    //       {
    //         style_code: '295597',
    //         position: '16',
    //       },
    //       {
    //         style_code: '294884',
    //         position: '17',
    //       },
    //       {
    //         style_code: '297869',
    //         position: '18',
    //       },
    //       {
    //         style_code: '294987',
    //         position: '19',
    //       },
    //     ],
    //     SKIRTS: [
    //       {
    //         style_code: '297926',
    //         position: '01',
    //       },
    //       // {
    //       //   style_code: null,
    //       //   position: '297926',
    //       // },
    //     ],
    //     KNITWEAR: [
    //       {
    //         style_code: '295575',
    //         position: '01',
    //       },
    //       {
    //         style_code: '295610',
    //         position: '02',
    //       },
    //       {
    //         style_code: '295573',
    //         position: '03',
    //       },
    //       {
    //         style_code: '295574',
    //         position: '04',
    //       },
    //       {
    //         style_code: '301505',
    //         position: '05',
    //       },
    //       // {
    //       //   style_code: null,
    //       //   position: '06',
    //       // },
    //       {
    //         style_code: '295602',
    //         position: '07',
    //       },
    //       {
    //         style_code: '295526',
    //         position: '08',
    //       },
    //     ],
    //     'TOPS AND BLOUSES': [
    //       {
    //         style_code: '294943',
    //         position: '01',
    //       },
    //       {
    //         style_code: '294972',
    //         position: '02',
    //       },
    //       {
    //         style_code: '301503',
    //         position: '03',
    //       },
    //       {
    //         style_code: '294997',
    //         position: '04',
    //       },
    //       {
    //         style_code: '297883',
    //         position: '05',
    //       },
    //       {
    //         style_code: '301502',
    //         position: '06',
    //       },
    //     ],
    //   },
    // },

    IMAGENS_CAPA_CATEGORIA_POR_DELIVERY: {
      // KEYS TEM QUE SER IGUAL NO DB
      ACCESSORIES: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/ACCESSORIES.jpg`,
        // 2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/ACCESSORIES.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/ACCESSORIES.jpg`,
      },
      DRESSES: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Ready_To_Wear/Categories/DRESSES.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Ready_To_Wear/Categories/DRESSES.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Ready_To_Wear/Categories/DRESSES.jpg`,
        holiday: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula1/Categories/DRESSES.jpg`,
        takeaway: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula2/Categories/DRESSES.jpg`,
        'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula3/Categories/DRESSES.jpg`,
        4: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/DRESSES.jpg`,
        5: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/DRESSES.jpg`,
        6: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/DRESSES.jpg`,
      },
      BOTTOMS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Ready_To_Wear/Categories/BOTTOMS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Ready_To_Wear/Categories/BOTTOMS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Ready_To_Wear/Categories/BOTTOMS.jpg`,
        // holiday: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula1/Categories/BOTTOM.jpg`,
        // takeaway: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula2/Categories/BOTTOM.jpg`,
        // 'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula3/Categories/BOTTOM.jpg`,
        4: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/BOTTOMS.jpg`,
        5: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/BOTTOMS.jpg`,
        6: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/BOTTOMS.jpg`,
        men: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Male/Categories/BOTTOMS.jpg`,
      },
      'TOPS AND BLOUSES': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Ready_To_Wear/Categories/TOPS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Ready_To_Wear/Categories/TOPS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Ready_To_Wear/Categories/TOPS.jpg`,
        holiday: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula1/Categories/TOPS.jpg`,
        takeaway: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula2/Categories/TOPS.jpg`,
        'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula3/Categories/TOPS.jpg`,
      },
      JUMPSUITS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Ready_To_Wear/Categories/JUMPSUITS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Ready_To_Wear/Categories/JUMPSUITS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Ready_To_Wear/Categories/JUMPSUITS.jpg`,
        // holiday: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula1/Categories/JUMPSUITS.jpg`,
        // takeaway: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula2/Categories/JUMPSUITS.jpg`,
        // 'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula3/Categories/JUMPSUITS.jpg`,
        4: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/JUMPSUITS.jpg`,
        5: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/JUMPSUITS.jpg`,
        6: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/JUMPSUITS.jpg`,
      },
      OUTERWEAR: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Ready_To_Wear/Categories/OUTERWEAR.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Ready_To_Wear/Categories/OUTERWEAR.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Ready_To_Wear/Categories/OUTERWEAR.jpg`,
        // holiday: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula1/Categories/OUTERWEAR.jpg`,
        // takeaway: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula2/Categories/OUTERWEAR.jpg`,
        // 'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula3/Categories/OUTERWEAR.jpg`,
        // 4: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/OUTERWEAR.jpg`,
        5: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/OUTERWEAR.jpg`,
        6: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/OUTERWEAR.jpg`,
      },
      KNITWEAR: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Ready_To_Wear/Categories/KNITWEAR.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Ready_To_Wear/Categories/KNITWEAR.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Ready_To_Wear/Categories/KNITWEAR.jpg`,
        holiday: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula1/Categories/KNITWEAR.jpg`,
        // takeaway: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula2/Categories/KNITWEAR.jpg`,
        'pre-spring': `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Capsula3/Categories/KNITWEAR.jpg`,
      },
      'FLAT SANDALS': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/FLAT_SANDALS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/FLAT_SANDALS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/FLAT_SANDALS.jpg`,
      },
      FLATS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/FLATS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/FLATS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/FLATS.jpg`,
      },
      'CLOGS AND MULES': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/CLOGS_AND_MULES.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/CLOGS_AND_MULES.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/CLOGS_AND_MULES.jpg`,
      },
      'HEELED SANDALS': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/HEELED_SANDALS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/HEELED_SANDALS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/HEELED_SANDALS.jpg`,
      },
      BAGS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/BAGS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/BAGS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/BAGS.jpg`,
      },
      SLIPPER: {
        // 1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/SLIPPER.jpg`,
        // 2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/SLIPPER.jpg`,
        // 3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/SLIPPER.jpg`,
      },
      SNEAKERS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Shoes/Categories/SNEAKERS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Shoes/Categories/SNEAKERS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Shoes/Categories/SNEAKERS.jpg`,
      },

      'BIKINI BOTTOMS': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/BIKINI_BOTTOMS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/BIKINI_BOTTOMS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/BIKINI_BOTTOMS.jpg`,
      },
      'BIKINI TOPS': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/BIKINI_TOPS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/BIKINI_TOPS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/BIKINI_TOPS.jpg`,
      },
      'COVER UP': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/COVER_UP.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/COVER_UP.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/COVER_UP.jpg`,
      },
      'ONE PIECE': {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/ONE_PIECE.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/ONE_PIECE.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/ONE_PIECE.jpg`,
      },
      SARONGS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/SARONGS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/SARONGS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/SARONGS.jpg`,
      },
      TOPS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/TOPS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/TOPS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/TOPS.jpg`,
        men: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Male/Categories/TOPS.jpg`,
      },
      BOTTOMS_SWIM: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/BOTTOMS.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/BOTTOMS.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/BOTTOMS.jpg`,
      },
      DRESS: {
        1: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery1/Swimwear/Categories/DRESSES.jpg`,
        2: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery2/Swimwear/Categories/DRESSES.jpg`,
        3: `https://storage.googleapis.com/showroom-bucket-images/${CURRENT_COLLECTION}/Delivery3/Swimwear/Categories/DRESSES.jpg`,
      },
    },

    categoriesOptions: [
      null,
      { name: 'DRESSES', value: 'DRESSES' },
      { name: 'TOPS & BLOUSES', value: 'TOPS AND BLOUSES' },
      { name: 'BOTTOMS', value: 'BOTTOMS' },
      { name: 'JUMPSUITS', value: 'JUMPSUITS' },
      { name: 'KNITWEAR', value: 'KNITWEAR' },
      { name: 'OUTERWEAR', value: 'OUTERWEAR' },
    ],

    categoriesOptionsSwim: [
      null,
      { name: 'BIKINI BOTTOMS', value: 'BIKINI BOTTOMS' },
      { name: 'BIKINI TOPS', value: 'BIKINI TOPS' },
      { name: 'COVER UP', value: 'COVER UP' },
      { name: 'SARONGS', value: 'SARONGS' },
      { name: 'TOPS', value: 'TOPS' },
      { name: 'BOTTOMS', value: 'BOTTOMS' },
      { name: 'DRESSES', value: 'DRESSES' },
      { name: 'JUMPSUITS', value: 'JUMPSUITS' },
    ],

    categoriesOptionsShoes: [
      null,
      { name: 'FLAT SANDALS', value: 'FLAT SANDALS' },
      { name: 'FLATS', value: 'FLATS' },
      { name: 'HEELED SANDALS', value: 'HEELED SANDALS' },
      { name: 'BAGS', value: 'BAGS' },
      { name: 'CLOGS AND MULES', value: 'CLOGS AND MULES' },
    ],
    categoriesOptionsMen: [
      null,
      { name: 'TOPS', value: 'TOPS' },
      { name: 'BOTTOMS', value: 'BOTTOMS' },
    ],
  },
};
